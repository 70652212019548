<template>
  <b-navbar id="coi-nav" type="dark" :variant="navBarVariant" class="py-0">
    <b-navbar-brand id="nav-home" class="py-3 mr-4"
      :disabled="$store.getters.isLoading"
      :to="{ name: 'SearchInbox' }">
      <div class="float-left mr-3"><OHSULogo /></div>
      <div v-if="$store.state.env.displayName" class="float-left">
        <div :style="{ lineHeight: '1.25'}">eCOI</div>
        <div id="nav-env" :style="{ lineHeight: '0.5', fontSize: '40%', color: 'rgba(255, 255, 255, 0.5)'}">
          {{ $store.state.env.displayName }}
        </div>
      </div>
      <div v-else class="float-left">eCOI</div>
    </b-navbar-brand>
    <b-navbar-nav v-if="$store.state.session.loggedIn">
      <b-nav-item
        id="nav-inbox"
        :disabled="$store.getters.isLoading"
        :to="{ name: 'SearchInbox' }" :active="$route.name === 'SearchInbox'">
        Inbox
      </b-nav-item>
      <b-nav-item
        id="nav-discs"
        :disabled="$store.getters.isLoading"
        :to="{ name: 'SearchDisclosures' }" :active="$route.name === 'SearchDisclosures'">
        Disclosures
      </b-nav-item>
      <b-nav-item
        id="nav-projects"
        :disabled="$store.getters.isLoading"
        :to="{ name: 'SearchProjects' }" :active="$route.name === 'SearchProjects'">
        Projects
      </b-nav-item>
      <b-nav-item
        v-if="showUser"
        :disabled="$store.getters.isLoading"
        id="nav-users"
        :to="{ name: 'SearchUsers' }"
        :active="$route.name === 'SearchUsers'"
      >
        Users
      </b-nav-item>

      <!-- This tab will eventually be accessible to all admins, but while new need to restrict to certain users -->
      <b-nav-item
        v-if="['85321', '98965', '101763', '80761'].includes($store.state.session.id)"
        :disabled="$store.getters.isLoading"
        id="nav-user-sync-errors"
        :to="{ name: 'SearchUserErrors' }"
        :active="$route.name === 'SearchUserErrors'"
      >
        User Sync Errors
      </b-nav-item>
    </b-navbar-nav>

    <b-navbar-nav class="ml-auto" v-if="$store.state.session.loggedIn">
      <b-nav-item
        id="nav-userprofile"
        :disabled="$store.getters.isLoading"
        :to="{ name: 'UserProfile', params: { userId: $store.state.session.id } }"
        :active="$route.name === 'UserProfile' && $route.params.userId === $store.state.session.id"
      >
        <fa-icon :icon="['far', 'id-card']" class="mr-1" />{{
          $store.state.session.preferredName
           ? $store.state.session.preferredName + ' ' + $store.state.session.lastName
           : $store.state.session.fullName
          }} Profile
      </b-nav-item>
      <b-nav-item
        id="nav-logout"
        :disabled="$store.getters.isLoading" @click="logoutAllWindows()">
        Log Out<fa-icon icon="sign-out-alt" class="ml-1" />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav>
      <b-nav-form id="user-alert" v-if="!!userAlertVariant">
        <b-button
          :disabled="$store.getters.isLoading"
          :variant="userAlertVariant" class="ml-2" size="lg" @click="$bvModal.show('modal-user-alerts');">
          <fa-icon icon="exclamation-triangle" />
        </b-button>
      </b-nav-form>
    </b-navbar-nav>

    <!-- Modal Popup showing user account alerts -->
    <b-modal
      id="modal-user-alerts"
      title="Account Alerts"
      size="lg"
      ok-only
      ok-title='Close'
      :static="testMode"
    >
      <b-alert v-if="pastDue || dueSoon" id="alert-due-soon" :variant="pastDue ? 'danger' : 'warning'" show>
        <div class="d-flex align-items-center">
          <fa-icon icon="exclamation-triangle" class="mr-3" />
          <span class="flex-grow-1">
            Your next disclosure submission is
            <template v-if="dueSoon">due soon</template><template v-if="pastDue">past due</template>.
            Create a new disclosure, or submit one you have already started on your
            <b-button size="xs" @click="$bvModal.hide('modal-user-alerts');" :to="{ name: 'SearchInbox' }">
              Inbox
            </b-button> page.
          </span>
        </div>
      </b-alert>
      <b-alert
        v-if="pwChangeSoon || pastPwChange"
        id="alert-due-soon"
        :variant="pastPwChange ? 'danger' : 'warning'"
        show
      >
        <div class="d-flex align-items-center">
          <fa-icon icon="exclamation-triangle" class="mr-3" />
          <span class="flex-grow-1">
            Your account password is
            <template v-if="pwChangeSoon">expiring soon</template><template v-if="pastPwChange">expired</template>.
            Navigate to your
            <b-button
              size="xs"
              @click="$bvModal.hide('modal-user-alerts');"
              :to="{
                name: 'UserProfile',
                params: { userId: $store.state.session.id },
                query: { pageId: 'form-page-userprofile-password' }
              }"
            >
              User Profile
            </b-button> page to update your account password.
          </span>
        </div>
      </b-alert>

      <!-- User Accounts Pending Banner - Hide if no User Accounts Pending -->
      <b-alert
        v-if="pendingUserAccounts.length"
        id="user-accounts-pending-banner"
        class="mt-3 mb-0"
        variant="info"
        show
      >
        <b-container fluid class="p-0">
          <b-row align-v="center" no-gutters>
            <b-col cols="auto" class="mr-3">
              <fa-icon icon="info-circle" class="fa-3x" />
            </b-col>
            <b-col>
              There {{pendingUserAccounts.length === 1 ? 'is' : 'are '}} {{pendingUserAccounts.length}}
              Self-Registered User Account{{pendingUserAccounts.length === 1 ? '' : 's '}} Pending Approval:
              <ul class="m-0">
                <li v-for="(pendingUser, i) in pendingUserAccounts" :key="`${pendingUser.id}-pending-account`">
                  {{pendingUser.fullName}}{{(i === pendingUserAccounts.length - 1) ? '' : ','}}
                </li>
              </ul>
            </b-col>
            <b-col cols="auto" class="ml-3">
              <b-button
                id="btn-view-pending-users"
                name="btn-view-pending-users"
                variant="info"
                @click="$bvModal.hide('modal-user-alerts');"
                :to="{
                  name: 'SearchUsers',
                  query: { searchOpts: { filters: { type: ['Self-Registered Pending'] } } }
                }"
              >
                View User{{pendingUserAccounts.length === 1 ? '' : 's'}}
                <fa-icon icon="external-link-alt" />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-alert>
    </b-modal>
  </b-navbar>
</template>

<script>
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import OHSULogo from '@/views/components/site-layout/OhsuLogo.vue';
import { genericGet } from '../../../controllers/common.controller';
import { CoiError } from '../../../frontEndErrorHandler';

export default {
  name: 'Navbar',
  components: {
    OHSULogo,
  },
  props: {
    testMode: { type: Boolean, default: false }, // for testing modal
  },
  data() {
    return {
      pendingUserAccounts: [],
    };
  },
  computed: {
    // Display user warnings in navbar if password will expire in less than 2 weeks, or if disclosure
    // is due in next month
    dueSoon() {
      return this.$store.state.session.nextDueDate
        && dayjs().isAfter(dayjs(this.$store.state.session.nextDueDate).subtract(30, 'day'))
        && dayjs().isBefore(dayjs(this.$store.state.session.nextDueDate));
    },
    pastDue() {
      return this.$store.state.session.nextDueDate
        && dayjs().isAfter(dayjs(this.$store.state.session.nextDueDate));
    },
    pwChangeSoon() {
      return this.$store.state.session.nextPwChangeDate
        && dayjs().isAfter(dayjs(this.$store.state.session.nextPwChangeDate).subtract(14, 'day'))
        && dayjs().isBefore(dayjs(this.$store.state.session.nextPwChangeDate));
    },
    pastPwChange() {
      return this.$store.state.session.nextPwChangeDate
        && dayjs().isAfter(dayjs(this.$store.state.session.nextPwChangeDate));
    },
    userAlertVariant() {
      if (!this.$store.state.session.loggedIn) return '';
      if (this.pastDue || this.pastPwChange) return 'danger';
      if (this.dueSoon || this.pwChangeSoon
        || (this.$store.state.session.isCoiAdmin === true && this.pendingUserAccounts.length)) return 'warning';
      return '';
    },
    isCurrentUserAdmin() {
      return this.$store?.state?.session?.isCoiAdmin || false;
    },
    hasContacts() {
      return isEmpty(this.$store.state.session.userContacts);
    },
    showUser() {
      return (
        (this.$store?.state?.session?.isCoiAdmin)
        || (this.$store.state.session.isReviewer)
        || (this.$store.state.session.isContact)
        || (this.$store.state.session.isCoiSuperReader)
      );
    },
    navBarVariant() {
      return `${this.$store.state.env.name === 'production' ? 'primary' : 'warning'}`;
    },
  },
  watch: {
    isCurrentUserAdmin(isAdmin) {
      this.$log.debug(`isCurrentUserAdmin watcher triggered on navbar: ${isAdmin}`);
      this.reloadPendingAccounts();
    },
  },
  methods: {
    /**
     * Method to reload test user list as needed
     */
    async reloadPendingAccounts() {
      try {
        if (this.isCurrentUserAdmin === true) {
          this.$log.debug('Reloading pending user account list');
          this.pendingUserAccounts = await genericGet('user/pendingAccounts');
        } else {
          this.pendingUserAccounts = [];
        }
      } catch (e) {
        throw new CoiError(e, 'Navbar.vue (postLogin)');
      }
    },

    logoutAllWindows() {
      function getCookie(cname) {
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      }
      // if this is the first window to log out (no loggedOut cookie present) set the loggedOut cookie and use the logout route
      if (!getCookie('loggedOut')) {
        document.cookie = 'loggedOut=2; expires=Wed, 31 Dec 2070 12:00:00 UTC; path=/';
        this.$router.push({ name: 'Logout' });
      } else {
        window.location.href = window.location.origin;
      }
    },
  },
  async mounted() {
    try {
      this.$log.debug(`Mounting Navbar: ${this.$store.state.session.isCoiAdmin}`);

      // Handle page refresh
      await this.$store.dispatch('refreshSession');
      await this.reloadPendingAccounts();

      // Trigger account pending reload from other places in the site as needed
      this.$root.$on('reloadPendingAccounts', async () => {
        await this.reloadPendingAccounts();
      });
    } catch (e) {
      throw new CoiError(e, 'Navbar.vue (mounted)');
    }
  },

  // Unsubscribe to above events when component is destroyed
  beforeDestroy() {
    try {
      this.$root.$off('reloadPendingAccounts');
    } catch (e) {
      throw new CoiError(e, 'Navbar.vue (beforeDestroy)');
    }
  },
};
</script>

<style lang="scss">
  nav#coi-nav {
    .navbar-brand {
      font-size: 200%;
      font-weight: 400;
      #ohsu-logo-icon {
        width: 35px;
        height: 55px;
      }
    }

    .navbar-nav {
      height: 100%;
      > li.nav-item {
        margin-top: 1rem;
        > a {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 1.5rem 1rem 1.5rem;
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
          font-weight: 400;
          color: $light;

          &.active {
            background-color: $light;
            color: $headings-color;
          }
          &:hover:not(.active) {
            background-color: rgba( $light, 0.7 );
            color: $headings-color;
          }
        }
      }
    }

    .userErrors {
      color: lighten($danger, 20%);
    }
  }
</style>
